import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner02.png'
import img01 from '../../assets/img/Recruit/Recruit00.png'
import img02 from '../../assets/img/Recruit01/Recruit01_02.png'
import btn01 from '../../assets/img/Recruit/btn01.png'
import btn02 from '../../assets/img/Recruit/btn02.png'
import btn from '../../assets/img/Work01/work01btn.png'
import btn03 from '../../assets/img/Recruit/btn03.png'
import btn04 from '../../assets/img/Recruit/btn04.png'
import btn05 from '../../assets/img/Recruit/btn05.png'
import btn06 from '../../assets/img/Recruit/btn06.png'
import btn07 from '../../assets/img/Recruit/btn07.png'
import square from '../../assets/img/Work01/square.png'
import imgCom from '../../assets/img/Recruit/RecruitCom.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    divStyle: {
      height: 'auto',
      width: '100%',
    },
    divBottom: {
      height: 'auto',
      width: '70%',
    },
    customTable: {
      marginTop: '5%',
      borderCollapse: 'collapse',
      fontFamily: 'noto Sans JP',
    },
    span: {
      backgroundColor: '#2085f6',
      color: 'white',
    },
    spanStyle: {
      width: '2%',
      height: '8%',
      paddingBottom: '1%',
    },
    imgSize: {
      width: '1px',
      height: '1px',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        {/* <script src="css/assets/jss/jquery.mCustomScrollbar.css"></script> */}
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{  color: 'rgb(16, 181, 236)'}}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　採用情報</dt>
                <dd>
                  <a href="/recruit01">インフラ</a>
                </dd>
                <dd>
                  <a href="/recruit02">無線通信</a>
                </dd>
                <dd>
                  <a href="/recruit03">開発</a>
                </dd>
                <dd>
                  <a href="/contact">応募登録</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <div>
                  <img src={img01} className="recruitImg01" />
                </div>
                <div className="recruitTitle" style={{textAlign:'left',paddingTop:'2%'}}>
                  SE･PG/面接1回/幅広い案件にアサイン可能/経験浅めOK/リモートワークOK/資格取得補助制度あり
                </div>
                <div className="recruitDetail">
                <span className="recruitSpan01">
                  アサイン先でクライアントとの間に対応が生じたとき、
                  今の営業担当に不安を感じていましたら、
                  ぜひGHインテグレーションにお越しください。
                  <br />
                  <br />
                  私は長年IT業界に腰を置き、少し前までは
                  とある企業で役員を務めていました。
                  <br />
                  任期を終え、新しく私を刺激してくれる会社がないか
                  そう探していたところ出会ったのがGHインテグレーションです。
                  <br />
                  <br />
                  最初に感じたのは社員の温かさです。
                  <br />
                  社員同士のコミュニケーションが活発に感じられました。
                  <br />
                  <br />
                  それはまさに代表が一番に大切にしていること。
                  <br />
                  代表は営業経験が長く、ご自身が本社のサポートに
                  不満や不安を感じた経験があったからこそ、
                  エンジニアが困ることないように
                  全社でフォローする会社を作りたいと当社ができました。
                  <br />
                  <br />
                  そんな設立背景や社風に惹かれて入社した私ですが、
                  <br />
                  仕事の中ではエンジニア皆さんの成長を見守ることを
                  楽しみに働いています。
                  <br />
                  もう一人の営業担当もそこは同じ気持ち。
                  <br />
                  連絡したのに無反応、一番困りますよね。
                  <br />
                  もうここでは困らせません。お任せください。
                  <br />
                  </span>
                </div>

                <div className="recruitdiv">
                  <table className="recruitTable">
                    <tr>
                      <td className="recruittd1" align="center">
                        募集背景
                      </td>
                      <td className="recruittd2">
                        元インフラエンジニアの代表によって設立された当社。もとよりインフラ案件には強かったのですが、
                        会社の規模拡大、開発エンジニアの要望を受け、SE・PG枠でも多数のエンジニアを採用することになりました。
                        できる限り希望を叶える、エンジニアが一人で困ることがないような環境をご用意致します。
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        仕事内容
                      </td>
                      <td className="recruittd2">
                        金融や証券などの業務システムやECサイトなどのWebシステムの開発に携わっていただきます。
                        <br></br>
                        <br></br>
                        あなたのご経験やご希望に応じて相談の上、
                        コンサルティング・要件定義から開発・運用・保守までアサイン。
                        <br></br>
                        AIやIoTなどの案件もご紹介できます。<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#0fd6db', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　開発環境・業務範囲　
                        </span>
                        <span>　</span>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　エンジニア限定取材　
                        </span>
                        <br></br>
                        <br></br>
                        〇某ネット銀行システム更改案件 <br></br>
                        　概要：ネット銀行基幹系システムの更改 <br></br>
                        　担当フェーズ：拡張機能の開発 <br></br>
                        　プロジェクト人数：8名（当社より2名参画） <br></br>
                        〇公的機関マイナンバー管理システム <br></br>
                        　概要：居住者のマイナンバー管理システム <br></br>
                        　担当フェーズ：保守フェーズ <br></br>
                        　プロジェクト人数：4名（当社社員1名参画） <br></br>
                        〇通信教育基幹系システム <br></br>
                        概要：教育機関の教材、試験、成績管理等の拡張 <br></br>
                        担当フェーズ：機能追加のため開発および既存システムの保守{' '}
                        <br></br>
                        <br></br>
                        開発言語：java等
                        <br></br>
                        <br></br>
                        その他… <br></br>
                        【言語】Python、PHP、Java、Perl、Objective-C、Swift、Ruby、GO、Kotlin、.NET(C#・ASP・VB)、C、C++、VC++、VB、VBA、RPG、COBOL{' '}
                        <br></br>
                        【フロントエンド】HTML、CSS、JavaScript <br></br>
                        【FW】Tomcat、Git、MyBatis、Ruby on Rails、Angular{' '}
                        <br></br>
                        【OS】Linux、UNIX、Windos、Android、iOS など <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　今後は拡大してチーム体制を整えていきます。 <br></br>
                        今回、入社直後は希望の案件に一人で入ることが考えられますが、今後はチームを強化していきます。当社ではインフラ案件が先行しており、こちらでは既にほとんどの現場でチームアサインに。営業担当のフォローがありますが、今後は開発案件もチームを増やしていき、お互いが支え合える環境にしていきます。実際、ほかの現場からは「周りが信頼できるからこそ、ストレスにならないし、イレギュラーも対応しやすくなる」との声がありました。{' '}
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　パートナー企業と最先端案件にアサインも可能 <br></br>
                        国際的に活躍するエンジニアが所属する企業とパートナーシップを結んでおり、経験が浅い方でもパートナー企業のベテランエンジニアと一緒に最先端の案件に入ることが可能！AIやIoTなど、この先の時代を形作る開発プロジェクトに設計などから入り経験を積むことができます。ストレスのない環境を手に入れられるだけでなく、技術向上も当社で叶えられます。{' '}
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        応募資格
                      </td>
                      <td className="recruittd2">
                        ◆開発エンジニアの実務経験3年程度<br></br>
                        （要件定義～運用・保守までスキルにあった案件があります）
                        <br></br>
                        ◆学歴不問
                        スキルアップできる環境やフォロー体制に定評があります！
                        <br></br>
                        経験豊富な先輩社員とチームアサインも可能。<br></br>
                        女性エンジニアも複数名活躍中です！<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        雇用形態
                      </td>
                      <td className="recruittd2">正社員</td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        想定給与
                      </td>
                      <td className="recruittd2">
                        月給27万円～80万円<br></br>
                        ★多くの方が前職を上回る給与で入社しています！ご期待ください
                        <br></br>
                        <br></br>
                        ※経験や能力、実績、前職給与を考慮の上、面談で話し合って決定します
                        <br></br>
                        ※上記には固定残業代（6万1720円～/35時間分）が含まれます
                        <br></br>
                        ※35時間超過の場合は別途残業代支給（平均残業は月10時間です）
                        <br></br>
                        ※試用期間（3ヶ月）の給与や待遇に差異はございません
                        <br></br>
                        <br></br>
                        ＜別途各種手当あり＞<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　実際の給与例　
                        </span>
                        <br></br>
                        ≪年収例≫<br></br>
                        ◆年収710万円／45歳（入社9年）<br></br>
                        ◆年収580万円／37歳（入社5年）<br></br>
                        ◆年収415万円／31歳（入社3年）<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務時間
                      </td>
                      <td className="recruittd2">
                        原則9:00～18:00（実働8時間／休憩60分）<br></br>
                        ※プロジェクトにより異なる場合あり<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　残業について　
                        </span>
                        <br></br>
                        平均10時間程度 <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務地
                      </td>
                      <td className="recruittd2">
                        テレワーク約50％◎東京23区内や神奈川県川崎市などのクライアント先
                        <br></br>
                        <br></br>
                        ※通勤時間やプロジェクト内容の希望を考慮して決定<br></br>
                        ※転居を伴う転勤なし<br></br>
                        <br></br>
                        ＜本社＞<br></br>
                        東京都千代田区紀尾井町4-1<br></br>
                        ニューオータニガーデンコート22F<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　交通・詳細　
                        </span>
                        <br></br>
                        ＜本社＞<br></br>
                        東京メトロ丸ノ内線(赤坂見附駅)より徒歩5分<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　勤務地エリア　
                        </span>
                        <br></br>
                        東京都(新宿区)、神奈川県、埼玉県、千葉県<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        休日休暇
                      </td>
                      <td className="recruittd2">
                        ＜年休120日＞<br></br> ◆完全週休2日制（土日）<br></br>{' '}
                        ◆祝日<br></br>
                        ◆年末年始休暇（12月29日～1月3日）<br></br>{' '}
                        ◆夏季休暇（3日間）<br></br>
                        ◆慶弔休暇<br></br>
                        ◆有給休暇（11～20日/年 ※勤続年数1年以上）<br></br>
                        ◆産前・産後休暇<br></br> ◆育児休暇<br></br> ◆介護休暇
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        待遇・福利厚生
                      </td>
                      <td className="recruittd2">
                        ◆昇給年1回（4月）<br></br>
                        ◆各種社会保険完備（雇用・労災・健康・厚生年金）<br></br>
                        ◆資格取得支援（合格時に受験料・講座費用を全額支給）
                        <br></br>
                        ◆社員旅行（年1回/1泊2日）<br></br>
                        ◆BBQ<br></br>
                        ◆誕生日プレゼント<br></br>
                        ◆服装自由　※勤務先により例外あり<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img02} className="work04Img03" />
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
